import { withPrefix } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import styled, { ThemeProvider } from 'styled-components';
import Footer from '../components/Footer';
import GlobalStyle from '../styles/GlobalStyle';
import theme from '../styles/theme';
import Header from './Header';
import SideButtons from './SideButtons';
import useSiteMetadata from './SiteMetadata';

const Site = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
`;
const Content = styled.div`
  flex: 1;
`;

const Layout = ({ isHome = false, children }) => {
  const { title, description, url } = useSiteMetadata();
  return (
    <ThemeProvider theme={theme}>
      <>
        <GlobalStyle />
        <Site>
          <Helmet>
            <html lang="es" />
            <title>{title}</title>
            <meta name="description" content={description} />

            <link
              rel="apple-touch-icon"
              sizes="180x180"
              href={`${withPrefix('/')}img/apple-touch-icon.png`}
            />
            <link
              rel="icon"
              type="image/png"
              href={`${withPrefix('/')}img/favicon-32x32.png`}
              sizes="32x32"
            />
            <link
              rel="icon"
              type="image/png"
              href={`${withPrefix('/')}img/favicon-16x16.png`}
              sizes="16x16"
            />
            <meta name="theme-color" content="#fff" />
            <meta property="og:site_name" content={url} />
            <meta property="og:type" content="business.business" />
            <meta property="og:title" content={title} />
            <meta property="og:url" content={url} />
            <meta
              property="og:image"
              content={`${withPrefix('/')}img/og-image.jpg`}
            />
          </Helmet>
          <Header noHeader={isHome} />
          <SideButtons />
          <Content>{children}</Content>
          <Footer isHome={isHome} />
        </Site>
      </>
    </ThemeProvider>
  );
};

Layout.propTypes = {
  noHeader: PropTypes.bool,
};

export default Layout;
