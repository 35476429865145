import { Link } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import logo from '../img/Juancho_Logo_Horizontal_White_RGB.png';
import Navbar from './Navbar';
import ContentContainer from './UI/ContentContainer';

const HeaderStyled = styled.header`
  position: relative;
`;
const HeaderBar = styled.div`
  height: 80px;
  background-color: ${props => props.theme.blue};
  padding: 0.5rem 1rem 0.5rem;
`;
const ContentContainerStyled = styled(ContentContainer)`
  display: flex;
  justify-content: flex-end;
  aling-items:center;
`;
const Img = styled.img`
  display: block;
  margin:5px 0 5px 0;
  height:50px;
`;

const Header = ({ noHeader }) => {
  return (
    <HeaderStyled>
      {!noHeader && (
        <HeaderBar>
          <ContentContainerStyled>
            <Link to="/" className="navbar-item" title="Logo">
              <Img src={logo} alt="Juancho te presta" />
            </Link>
          </ContentContainerStyled>
        </HeaderBar>
      )}
      <Navbar isHome={noHeader} />
    </HeaderStyled>
  );
};

Header.propTypes = {
  noHeader: PropTypes.bool,
};

export default Header;
