import { css } from 'styled-components';

const SVGColor = color => css`
  .fill-color {
    fill: ${color};
  }
  .stroke-color {
    stroke: ${color};
  }
`;

export default SVGColor;
