import styled from 'styled-components';

const Nav = styled.nav`
  ul {
    list-style-type: none;
    padding: 0;
  }
`;

export default Nav;
