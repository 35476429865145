export default {
  grayLight: '#f4f4f4',
  gray200: '#e8e8e8',
  gray: '#616E81',
  grayDark: '#323232',
  blueLight: '#009ded',
  blue: '#305CFF',
  blueDark: '#0A2880',
  blueLightness:'#005D8F',
  red: '#FF2C00',
  redMenu: '#FF2C00',
  yellow: '#FFCD00',
  yellowLight:'#FFF705',
  purple: '#6235CD',
  purpleLight:'#8A69E0',
  white:'#fff',
  colorYellow:'#FFCD00',
  colorLowYellow:'#DFA500',
  colorHighYellow:'#FFE332',

  colorBlue:'#305CFF',
  colorLowBlue:'#173FDD',
  colorHighBlue:'#5181FF',

  colorRed:'#FF2C00',
  colorLowRed:'#C61800',
  colorHighRed:'#FF6045',

  colorGray:'#616E81',
  colorLowGray:'#323F53',
  colorHighGray:'#848DA6',

  colorPurple:'#885BFF',
  colorLowPurple:'#6235CB',
  colorHighPurple:'#AA83FF',

  colorGreen:'#45DC91',
  colorLowGreen:'#27AD68',
  colorHighGreen:'#7BFFBD',

  colorOrange:'#F97134',
  colorLowOrange:'#D25012',
  colorHighOrange:'#FF9968',
};
