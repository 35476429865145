import React from 'react';
import styled, { css } from 'styled-components';
import Apply from '../img/icons/apply-for-credit.svg';
import Whatsapp from '../img/icons/whatsapp.svg';
import SVGColor from './UI/SVGColor';

const SideButtonsStyled = styled.div`
  position: fixed;
  bottom: 143px;
  right: 0;
  transform: translate(0, -50%);
  display: flex;
  z-index: 2000;
  color: transparent;
  flex-direction: column;

  :hover {
    color: ${props => props.theme.red};
    font-size: 17px;
  }
`;
const SideButton = css`
  border-top-left-radius: 0.5rem;
  border-bottom-left-radius: 0.5rem;
  text-decoration: none;
  margin: 0.5rem 0;
  height: 2.95rem;
  width: 3.1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 0 7px rgba(255, 255, 255, 0.5);
  transition: box-shadow 0.3s ease-in-out;
    
  ${SVGColor('#fff')}
  &:hover {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
    
  }
`;
const Button1 = styled.a`
  ${SideButton}
  background-color: ${props => props.theme.gray};
  margin-left:5px;

`;
const Button2 = styled.a`
  ${SideButton}
  background-color:${props => props.theme.gray};
  margin-left: 5px;
`;


const SideButtons = () => {
  return (
    <SideButtonsStyled>
      <Button1 href={`${process.env.GATSBY_JTP_APP}/signup`}  aria-label="Regístrate" >
        <Apply />
      </Button1>
      <Button2 href="https://wa.me/5744807833" aria-label="whatsapp">
        <Whatsapp />
      </Button2>
    </SideButtonsStyled>
  );
};

export default SideButtons;
