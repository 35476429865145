import styled from 'styled-components';

const ContentContainer = styled.div`
  width: 100%;
  max-width: 70rem;
  margin: 0 auto;
  p {
    text-align: justify;
    a {
      text-decoration: none;
      color: ${props => props.theme.red};
    }
  }
`;

export default ContentContainer;
