import { createGlobalStyle } from 'styled-components';
import 'typeface-roboto';
import AcuminVariableConcept from '../AcuminVariableConcept.otf';
import { breakpointLarge } from './breakpoints';
import normalize from './normalize';

const GlobalStyle = createGlobalStyle`
 @font-face{
  font-family:'Acumin Variable Concept';
  src: url(${AcuminVariableConcept});
 }
  ${normalize}
  *, *:before, *:after {
    box-sizing: border-box;
  }
  body {
    font-family:'Acumin Variable Concept', Roboto, sans-serif;
    font-size: 0.875rem;
    color: ${props => props.theme.grayDark};
    background-color: ${props => props.theme.grayLight};
    @media (min-width: ${breakpointLarge}) {
      font-size: 1.25rem
    }
  }
  .lc-link {
    z-index: 1000!important;
  }
  #netlify-identity-widget {
    z-index: 99999 !important;
  }
  h1 {
    font-size: 2rem;
    font-weight: 400;
    color: ${props => props.theme.blueDark};
  }
  h2 {
    font-size: 1rem;
    color: ${props => props.theme.blueDark};
    @media (min-width: ${breakpointLarge}) {
      font-size: 2rem;
    }
  }
`;

export default GlobalStyle;
